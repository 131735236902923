const config = {
    devCenterUI: {
        id: "821e2209-bebf-4a7c-8e19-fdab5b8be3e8",
        name: "developer-center-ui",
    },
    auth0ClientId: {
        test: "95WzMD3pE5V3Fo1FhKh3TWkKKzCpvpG1",
        prod: "RQCOLMEURd3q0hv3O8IxAxBFPVHF0qiw",
    },
    auth0Domain: {
        test: "auth.test.spsapps.net",
        prod: "auth.spscommerce.com",
    },
    authRealm: {
        test: "identity-test",
        prod: "identity",
    },
    kubeRoot: {
        test: "https://api.test.spsapps.net",
        prod: "https://api.spscommerce.com",
    },
    identityRoot: {
        test: "https://test.id.spsc.io",
        prod: "https://id.spsc.io",
    },
    recaptchaKeys: {
        test: "6LfAWtUqAAAAAP7FKIy-FJxq7mQPM0YG47PQj2jP",
        prod: "6LeHWtUqAAAAAI4j9-TZPtYAxHHb2aOeYoK-QcTN",
    },
    orgId: {
        test: "00D3J0000000Qf9",
        prod: "00D300000000bzv",
    },
    salesforceSalesFormUrl: {
        test: "https://go.spscommerce.com/l/28312/2018-08-16/8nzkqv",
        prod: "https://go.spscommerce.com/l/28312/2018-08-16/8nzkqv",
    },
    salesforceContactFormUrl: {
        test:
            "https://spscommerce--spstest.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
        prod: "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
    },
    documentation: {
        atlasDocsUrl: "https://docs.platform.spscommerce.com/",
        authenticationServiceId: "3be767b1-58cc-4530-b711-71cb1576f8dd",
        gettingStartedCategoryWhitelist: [
            "86434684-ddbc-4e6b-a116-65f507b33a2d",
            "66c390f7-9075-4bc1-857f-d6d4f495ee6d",
        ],
        internalBuildGuideCategoryWhitelist: ["a7b21ed0-4c83-495c-88dc-8cf124e1aa48"],
        authenticationWhitelist: ["3be767b1-58cc-4530-b711-71cb1576f8dd"],
        internalDevelopmentToolsCategoryWhitelist: [
            "edf70e3a-33db-4d45-86e9-3f4663fb742e",
            "818b4b6d-a654-49c8-8843-dfa2dda50c11",
            "c637f698-8eed-4d27-b9ac-7dc881038fcf",
            "30cf47ad-8bfe-4f67-928c-bbe8e15f143b",
            "73e221b6-d40e-491b-bf78-5dc3be424136",
            "934cdec1-2166-4783-9765-ae8ade721f8b",
        ],
        serviceDocsBlacklist: {
            test: [],
            prod: [],
        },
    },
    sidenavRulesCharacter: "|",
    sideNavLockByName: [],
    sideNavLockByLink: [
        "/docs/shipping-doc-api/labels/shipping_label_object",
        "/docs/shipping-doc-api/packing_slips/packing_slip_object",
    ],
    swaggerSidebarRules: {
        INTERNAL: "internal",
        LOGIN_RESTRICTED: "login",
    },
    tools: {
        toolsArray: [
            {
                hideInEnvironments: ["prod"],
                label: "Example Tool",
                route: "example-tool/",
                id: 101,
                description: "Internal-only example tool for SPS developers",
                internal: true,
            },
            {
                label: "Shipping Label and Packing Slip Browser",
                route: "shipping-doc-browser/",
                id: 102,
                description: "Browse retailer shipping labels and packing slips",
            },
        ],
    },
    roleIndependentPaths: ["/contact-info", "/locations", "/terms-of-use"],
    rsx: {
        test: "https://network-toolbox.test.spsapps.net/rsx",
        prod: "https://network-toolbox.spsapps.net/rsx",
        baseSchemaVersion: ["7.7.0"],
        sipVersion: "7.1.0",
        legacyDocs: [
            "AdvanceShipNotices",
            "InventoryInquiriesAndAdvices",
            "WarehouseShippingAdvices",
            "WarehouseShippingOrders",
            "PurchaseOrderAcks",
            "PurchaseOrderChanges",
            "PurchaseOrders"
        ],
        documentNumbers: {
            Invoices: "810, 880, INVOIC",
            OrderChanges: "860, ORDCHG",
            WarehouseTransferReceiptAdvices: "944",
            ProductActivities: "852, SLSRPT",
            PlanningSchedules: "830, DELFOR",
            CarrierLoadTenders: "204",
            OrganizationalRelationships: "816",
            PurchaseOrders: "850, 875, ORDERS",
            PurchaseOrderAcks: "855, ORDRSP",
            AdvanceShipNotices: "856, DESADV",
            InventoryInquiriesAndAdvices: "846, PRICAT",
            PurchaseOrderChanges: "860, ORDCHG",
            WarehouseShippingOrders: "940",
            WarehouseShippingAdvices: "945",
            CreditDebitAdjustments: "812",
            MotorCarrierFreightDetails: "210",
            OrderStatusInquiries: "869",
            OrderStatusReports: "870",
            PurchaseOrderChangeAcks: "865",
            Remittances: "820",
            RequestRoutingInstructions: "753",
            RoutingInstructions: "754",
            WarehouseInventoryAdjustmentAdvices: "947",
            WarehouseTransferShipmentAdvices: "943",
        },
        csvDocuments: {
            networkSchemas: {
                "Orders.csv": ["7.7.0"],
                "OrderAcks.csv": ["7.7.0"],
                "Shipments.csv": ["7.7.0"],
                "Invoices.csv": ["7.7.0"],
                "ItemRegistries.csv": ["7.7.0"],
                "OrderChanges.csv": ["7.7.0"],
                "OrderChangeAcks.csv": ["7.7.0"],
            },
            qualifiedSchemas: {
                "Orders.csv": ["7.7.4"],
                "OrderAcks.csv": ["7.7.4"],
                "Shipments.csv": ["7.7.4"],
                "Invoices.csv": ["7.7.4"],
                "ItemRegistries.csv": ["7.7.4"],
                "OrderChanges.csv": ["7.7.4"],
                "OrderChangeAcks.csv": ["7.7.4"],
            },

        },
        positionalCsvDocuments: {
            networkSchemas: {
                "Orders.positional": ["7.7.0"],
                "OrderAcks.positional": ["7.7.0"],
                "Shipments.positional": ["7.7.0"],
                "Invoices.positional": ["7.7.0"],
                "ItemRegistries.positional": ["7.7.0"],
                "OrderChanges.positional": ["7.7.0"],
                "OrderChangeAcks.positional": ["7.7.0"],
            },
            qualifiedSchemas: {
                "Orders.positional": ["7.7.4"],
                "OrderAcks.positional": ["7.7.4"],
                "Shipments.positional": ["7.7.4"],
                "Invoices.positional": ["7.7.4"],
                "ItemRegistries.positional": ["7.7.4"],
                "OrderChanges.positional": ["7.7.4"],
                "OrderChangeAcks.positional": ["7.7.4"],
            },

        },
        descriptions: {
            Orders: `Order documents enables a buyer to issue an order, communicating to a supplier the specific items, price, and quantities they wish to have delivered.`,
            PurchaseOrders: `Order documents enables a buyer to issue an order, communicating to a supplier the specific items, price, and quantities they wish to have delivered.`,
            OrderAcks: `The Order Acknowledgement document is used by the supplier to indicate to the buyer that their order will or will not be filled as requested. Specific details such as items, price and quantities delivered can be represented in the document.`,
            PurchaseOrderAcks: `The Order Acknowledgement document is used by the supplier to indicate to the buyer that their order will or will not be filled as requested. Specific details such as items, price and quantities delivered can be represented in the document.`,
            Shipments: `This document gives the retailer advance notice of the shipment, enabling them to schedule the receipt of the product and to alert them of any shortages in the shipment.`,
            AdvanceShipNotices: `This document gives the retailer advance notice of the shipment, enabling them to schedule the receipt of the product and to alert them of any shortages in the shipment.`,
            Invoices: `Document is sent by the supplier to provide the buyer with details about a purchase, including items, price and quantities delivered. Includes payment details and terms, and is usually transmitted in response to an Order as a request for payment once the goods have shipped or services are provided.`,
            ItemRegistries: `The primary purpose of the inventory advice is for the supplier to provide the purchaser the amount of goods they have on hand and on order including future date. This allows better management of buying needs and is used primarily in a drop ship order management model to support e-commerce management.`,
            InventoryInquiriesAndAdvices: `The primary purpose of the inventory advice is for the supplier to provide the purchaser the amount of goods they have on hand and on order including future date. This allows better management of buying needs and is used primarily in a drop ship order management model to support e-commerce management.`,
            OrderChanges: `The Order Change may also be used by buyers to confirm their acceptance of changes to purchase orders made by the supplier. This document provides information describing the original purchase order, as well as changes to that purchase order. Changes may include adding items, changing items, changing dates, changing quantity, changing prices or rescheduling an order.`,
            PurchaseOrderChanges: `The Order Change may also be used by buyers to confirm their acceptance of changes to purchase orders made by the supplier. This document provides information describing the original purchase order, as well as changes to that purchase order. Changes may include adding items, changing items, changing dates, changing quantity, changing prices or rescheduling an order.`,
            OrderChangeAcks: `The Order Acknowledgement document is used by the supplier to indicate to the buyer that their order will or will not be filled as requested. Specific details such as items, prices, and quantities delivered can be represented in the document.`,
            WarehouseTransferReceiptAdvices: `Used in warehouse fulfillment service providers. Represents a notification by the 3PL DC about products transfers being received.`,
            ProductActivities: `Used in retail and grocery to provide sales and store inventory to suppliers. Also used by grocery to report sales for scan based trading relationships (in this usage, the grocer also create an internal invoice to pay the supplier), also known for use with consignment relationships.`,
            PlanningSchedules: `Used in retail to share with the suppliers forecast/future purchase plans.`,
            CarrierFreightDetails: `Used by motor carriers to invoice either retailers, suppliers, manufacturers etc for freight services.`,
            CarrierLoadTenders: `Used by shipper or consignee requesting a shipment to be picked up and routed. Used for single stop routes, LTL (less than truck load) or TL (truck load).`,
            CarrierLoadTenderResponses: `Acceptance of the load tender.`,
            CarrierStatusMessages: `Used in retail and grocery where the carrier provides the status of a shipment moved by a transportation carrier.`,
            OrganizationalRelationships: `Used by retailers to report the store number and addresses of their locations. Can be a complete list or an update of the store details.`,
            WarehouseShippingOrders: `Used in warehouse fulfillment service providers. Represents a request from the retailer or supplier to have the 3PL DC fill/pick products to be shipped.`,
            WarehouseShippingAdvices: `Used by a third party remote warehouse to inform a supplier that the shipment has been sent out to the end user.`,
        },
        help: {
            Orders: `Request goods or services.`,
            PurchaseOrders: `Request goods or services.`,
            OrderAcks: `Accept or reject order changes, or request changes for a previous order.`,
            PurchaseOrderAcks: `Accept or reject order changes, or request changes for a previous order.`,
            Shipments: `Give advance notice of the shipment.`,
            AdvanceShipNotices: `Give advance notice of the shipment.`,
            Invoices: `Request payment for delivered products.`,
            ItemRegistries: `Share detailed product and price information.`,
            InventoryInquiriesAndAdvices: `Share detailed product and price information.`,
            OrderChanges: `Describe the original order, and any changes to that order.`,
            PurchaseOrderChanges: `Describe the original order, and any changes to that order.`,
            OrderChangeAcks: `Accept or reject order changes, or request changes for a previous order.`,
            WarehouseTransferReceiptAdvices: `Share information on product inventory levels at the warehouse.`,
            ProductActivities: `Share detailed sales and inventory information.`,
            PlanningSchedules: `Share anticipated demands of products within a specific time period.`,
            CarrierFreightDetails: `Request payment for freight Services`,
            CarrierLoadTenders: `Request that a shipment be moved.`,
            CarrierLoadTenderResponses: `Accept or reject the tender sent in CarrierLoadTenders.`,
            CarrierStatusMessages: `Share shipment status detail.`,
            OrganizationalRelationships: `Share location addresses or organizational relationships between locations.`,
            WarehouseShippingOrders: `Authorize a third-party warehouse or logistic firm to make a shipment on their behalf.`,
            WarehouseShippingAdvices: `Authorize a third-party warehouse or logistic firm to make a shipment on their behalf.`,
        },
        documentsWithMockData: [
            "Invoices",
            "ItemRegistries",
            "OrderAcks",
            "OrderChangeAcks",
            "OrderChanges",
            "Orders",
            "Shipments",
        ],
        documentOrderForOlderVersions: [
            "PurchaseOrders",
            "PurchaseOrderAcks",
            "AdvanceShipNotices",
            "Invoices",
            "InventoryInquiriesAndAdvices",
            "PurchaseOrderChanges",
            "WarehouseShippingOrders",
            "WarehouseShippingAdvices",
            "WarehouseTransferReceiptAdvices",
            "OrganizationalRelationships",
        ],
        documentOrderForNewerVersions: [
            "Orders",
            "OrderAcks",
            "Shipments",
            "Invoices",
            "ItemRegistries",
            "OrderChanges",
            "OrderChangeAcks",
            "WarehouseTransferReceiptAdvices",
            "ProductActivities",
            "PlanningSchedules",
            "CarrierFreightDetails",
            "CarrierLoadTenders",
            "CarrierLoadTenderResponses",
            "CarrierStatusMessages",
            "OrganizationalRelationships",
            "AdvanceShipNotices",
            "InventoryInquiriesAndAdvices",
            "WarehouseTransferReceiptAdvices",
            "WarehouseShippingAdvices",
            "WarehouseShippingOrders",
            "PurchaseOrderAcks",
            "PurchaseOrderChanges",
            "PurchaseOrders",
        ],
    },
    sentry: {
        dsn: "https://38affedd44044ad8b0ca8f64fe7ecd5c@o85353.ingest.sentry.io/5763253",
        tracesSampleRate: 1.0,
    },
    envMap: {
        "https://localhost:8100": "test",
        "https://cdn.dev.spsc.io": "test",
        "https://cdn.test.spsc.io": "test",
        "https://test.developercenter.spscommerce.com": "test",
        "https://test.devcenter.spscommerce.com": "test",
        "https://developercenter.spscommerce.com": "prod",
        "https://devcenter.spscommerce.com": "prod",
    },
};

export default config;
